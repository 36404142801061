<template>
  <v-container>
     <div id="chart">
        <apexchart type="radar" height="350" :options="chartOptions" :series="series"></apexchart>
      </div>
    <div>
      <v-btn @click="run()">run</v-btn>
      <v-btn @click="test()">test</v-btn>
      <v-btn @click="reset()">reset</v-btn>
    </div>
    <v-row justify="center">
      <v-slide-group
      v-model="model"
      class="pa-4"
      show-arrows
    >
      <v-slide-item
        v-for="n in 5"
        :key="n"
        v-slot:default="{ active, toggle }"
      >
        <v-card
          :color="active ? 'primary' : 'grey lighten-1'"
          class="ma-1 px-4"
          height="36"
          width="36"
          @click="toggle"
        >
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-scale-transition>
              <v-icon
                v-if="active"
                color="white"
                size="48"
                v-text="'mdi-close-circle-outline'"
              ></v-icon>
            </v-scale-transition>
          </v-row>
        </v-card>
      </v-slide-item>
    </v-slide-group>
    </v-row>
    <v-row>
      <iframe width="1440" height="762" src="https://www.youtube-nocookie.com/embed/hMsGfMVa-gk"
        frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
    </v-row>
  </v-container>
</template>

<script>
// import axios from 'axios';
import memberService from '../library/member';
import fieldValue from '../firebase/firestore/fieldValue';
import firebaseStorage from '../firebase/storage/index';


export default {
  data: () => ({
    model: null,
    img: '',
    series: [{
      name: 'Points',
      data: [2, 10, 8, 8],
    }],
    chartOptions: {
      chart: {
        height: 350,
        type: 'radar',
      },
      xaxis: {
        categories: ['Openness', 'Courage', 'Endurance', 'Reflection'],
      },
      markers: {
        size: 4,
        colors: ['#fff'],
        strokeColor: '#FF4560',
        strokeWidth: 2,
      },
    },
  }),
  methods: {
    async run() {
      // this.img = await firebaseStorage.getPromoImgRef('AP700D0720.jpg');
      // console.log(ref);
      console.log(this.$store.getters);
      this.$store.dispatch('setLoading', true);
      await memberService.applyMember(15000);
      this.$store.dispatch('setLoading', false);
      // axios.post('http://localhost:5000/staging-achieve-plus/asia-northeast1/testFunction');
    },
    async test() {
      this.$store.dispatch('setLoading', true);
      const res = await memberService.checkStatusIsMem();
      console.log(res);
      this.$store.dispatch('setLoading', false);
    },
    async reset() {
      const data = {
        convertedPoints: fieldValue.deleteField(),
        currPoints: fieldValue.deleteField(),
        rankPoints: fieldValue.deleteField(),
        expHis: fieldValue.deleteField(),
        extraPoints: fieldValue.deleteField(),
        isMember: fieldValue.deleteField(),
        lastUpdate: fieldValue.deleteField(),
        pointHis: fieldValue.deleteField(),
        purchasedPoints: fieldValue.deleteField(),
        memberLevel: fieldValue.deleteField(),
        startMember: fieldValue.deleteField(),
      };
      const { uid } = this.$store.getters.user;
      this.$store.dispatch('setLoading', true);
      await this.$store.dispatch('setProfile', { uid, data });
      this.$store.dispatch('setLoading', false);
    },
  },
  async mounted() {
    console.log('start');
    console.log(this.$store.getters);
    this.img = await firebaseStorage.getPromoImgRef('AP700D0720.jpg');
  },
};
</script>

<style>

</style>
